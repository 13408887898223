import React from "react";
import {Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Link} from "gatsby-theme-material-ui";
import {WelcomeDashboardLayout} from "src/components/layout";
import {AccountBalance, AlternateEmail, Business, EuroSymbol, Phone} from "@mui/icons-material";

export default function SiteNotice() {
  return (
    <WelcomeDashboardLayout titlePrefix="Impressum" preferRows>
      <Typography variant="h5" component="h1" gutterBottom>
        Impressum
      </Typography>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Typography variant="body1">
            Im Auftrag von
            {' '}<Link color="inherit" href="https://www.privatfunk.de/" target="_blank">
            APR
          </Link>{' '}
            und
            {' '}<Link color="inherit" href="https://www.vau.net/" target="_blank">
            VAUNET
          </Link>{' '}
            wird diese Seite betrieben von der
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Business/>
              </ListItemIcon>
              <ListItemText
                primary="SOLE Software GmbH"
                secondary={
                  <>
                    Sommerbergstraße 97<br/>
                    66346 Püttlingen<br/>
                    Deutschland
                    <Box component="span" display="block" mt={1}>vertreten durch den Geschäftsführer Dominik Leibenger</Box>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Phone/>
              </ListItemIcon>
              <ListItemText
                primary="+49 6806 4999535-0"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AlternateEmail/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link to="mailto:kontakt@sendemeldung.de" color="primary">
                    kontakt@sendemeldung.de
                  </Link>
                }
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <ListItem>
              <ListItemIcon>
                <EuroSymbol/>
              </ListItemIcon>
              <ListItemText
                primary="DE 306 343 092"
                secondary="Umsatzsteuer-ID"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AccountBalance/>
              </ListItemIcon>
              <ListItemText
                primary="HRB 103260, Amtsgericht Saarbrücken"
                secondary="Handelsregistereintrag"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </WelcomeDashboardLayout>
  );
}
